import React from 'react'
import { FaTiktok, FaInstagram, FaStrava } from 'react-icons/fa'
import { FooterContainer, FooterWrap, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import whiteLogoImage from '../../images/new-run-club-logo.png'; 

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              <img src={whiteLogoImage} alt="running on beans" style={{ width: '200px', height: 'auto' }} />
            </SocialLogo>
            <WebsiteRights>running on beans © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.tiktok.com/@runningonbeans_?_t=8l8fSMi98aH&_r=1' target='_blank' arial-label='TikTok'>
                <FaTiktok />
              </SocialIconLink>
              <SocialIconLink href='//www.instagram.com/runningonbeansgalway/' target='_blank' arial-label='Instagram'>
                <FaInstagram/>
              </SocialIconLink>
              <SocialIconLink href='https://www.strava.com/clubs/1231233?share_sig=0FDE3E121711491398&_branch_referrer=H4sIAAAAAAAAAwXB2w6CIAAA0D9Ki7zU1hpoOruoZVdfGGamBIWg2Pr6zqm7Tqi5YahOEk1GRIgRa94vA4F8yG1g11GxrIo7a8pFCiHJZ2jdlVHlnv0g1jd7B5RFrxx6U0g9n%2FDLrVZPfQ%2BRPzRYRiJdgy0b4uKDyYNjuLfCQ9MyU%2F9Wm1m2953jN3Oq4ZFgnE5Ok5cTWfLpAnGyUO%2BZFW3P8ZcKdyN3CQ3GB95%2F%2FpwUreiwAAAA&fbclid=PAAaZ9BJtdIf8VDFNvY6M3s5jXmAC4AjCDamWYhsgvcGBDwi_rIpPJ3LlwNbo_aem_AQ5GRiql0vzEK9SQD7TxS7fweO__P2U2k7I5rg83pU5BuC0fjqVNxjp8KrMOjF1Rmuo&_branch_match_id=1293879618704052995' target='_blank' arial-label='Strava'>
                <FaStrava/>
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
