import React, { useState, useEffect } from 'react';
import comingSoon from '../../images/coming-soon.svg';
import {
  ServicesContainer,
  ServicesH1,
  PopupContainer,
  PopupButton,
} from './ServiceElements';
import ShopifyBuyButton from './ShopifyBuyButton'; 
import './Countdown.css'; // Import the CSS file for styling

const Service = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2024-09-21T09:00:00'); 
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  /**
   * To add the shopify stuff in
   * Replace the 
   * countdown and image
   * with
   * <ShopifyBuyButton/>
   */

  return (
    <ServicesContainer id='service'>
      <ServicesH1>Shop</ServicesH1>
      <div className="countdown-wrapper">
        <div className="countdown">
          <div className="countdown-item">
            <span className="countdown-number">{timeLeft.days}</span>
            <span className="countdown-label">Days</span>
          </div>
          <div className="countdown-item">
            <span className="countdown-number">{timeLeft.hours}</span>
            <span className="countdown-label">Hours</span>
          </div>
          <div className="countdown-item">
            <span className="countdown-number">{timeLeft.minutes}</span>
            <span className="countdown-label">Minutes</span>
          </div>
          <div className="countdown-item">
            <span className="countdown-number">{timeLeft.seconds}</span>
            <span className="countdown-label">Seconds</span>
          </div>
        </div>
      </div>
      <img src={comingSoon} alt="Coming Soon" style={{ width: '90%' }} />
    </ServicesContainer>
  );
};

export default Service;
