import React from 'react'
import { Button2 } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'

const Info2 = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
    return (
      <>
        <InfoContainer lightBg={lightBg} id={id}>
          <InfoWrapper>
            <InfoRow imgStart={imgStart}>
              <Column1>
                <TextWrapper>
                  <TopLine>{topLine}</TopLine>
                  <Heading lightText={lightText}>{headline}</Heading>
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                  <BtnWrap>
                    <Button2 
                    href='https://www.strava.com/clubs/1231233?share_sig=0FDE3E121711491398&_branch_referrer=H4sIAAAAAAAAAwXB2w6CIAAA0D9Ki7zU1hpoOruoZVdfGGamBIWg2Pr6zqm7Tqi5YahOEk1GRIgRa94vA4F8yG1g11GxrIo7a8pFCiHJZ2jdlVHlnv0g1jd7B5RFrxx6U0g9n%2FDLrVZPfQ%2BRPzRYRiJdgy0b4uKDyYNjuLfCQ9MyU%2F9Wm1m2953jN3Oq4ZFgnE5Ok5cTWfLpAnGyUO%2BZFW3P8ZcKdyN3CQ3GB95%2F%2FpwUreiwAAAA&fbclid=PAAaZ9BJtdIf8VDFNvY6M3s5jXmAC4AjCDamWYhsgvcGBDwi_rIpPJ3LlwNbo_aem_AQ5GRiql0vzEK9SQD7TxS7fweO__P2U2k7I5rg83pU5BuC0fjqVNxjp8KrMOjF1Rmuo&_branch_match_id=1293879618704052995'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    primary='true'
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                    >{buttonLabel}
                    </Button2>
                  </BtnWrap>
                </TextWrapper>
              </Column1>
              <Column2>
                <ImgWrap>
                  <Img src={img} alt={alt}/>
                </ImgWrap>
              </Column2>
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
      </>
    )
  }
  
  export default Info2