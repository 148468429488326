import styled from 'styled-components';
import {Link} from 'react-scroll'

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({primary}) => (primary ?  '#0f222d' : '#fff')};
  white-space: nowrap;
  padding: ${({big}) =>  (big ? '14px  48px' : '12px 30px')};
  color: ${({dark}) =>  (dark ? '#f55139' : '#f55139')};
  font-size:${({fontBig}) =>  (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ?  '#fff' : '#0f222d')};

  }
`

export const Button2 = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: ${({ primary }) => (primary ? '#0f222d' : '#f55139')};
  color: #fff;
  text-decoration: none;
  border-radius: 50px;

  &:hover {
    background: ${({ primary }) => (primary ? '#fff' : '#0f222d')};
    color: ${({ primary }) => (primary ? '#0f222d' : '#fff')};
  }
`;