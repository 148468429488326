import React, {useState} from 'react'
import Footer from '../components/Footer';
import Hero from '../components/Hero'
import Info from '../components/Info'
import Info2 from '../components/Info/Info2'
import Info3 from '../components/Info/Info3';
import { homeObjOne, homeObjTwo, homeObjThree} from '../components/Info/Data';
import Navbar from '../components/Navbar'
import Sidebar from '../components/SideBar'
import Services from '../components/Services'
import { Helmet } from 'react-helmet';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Helmet>
        <title>Running on Beans - Galway's Newest Running Club</title>
        <meta name="description" content="Join Galway's premier run club for a coffee run every Saturday. Explore new cafes, meet new friends, enjoy great company, and stay active. Perfect for coffee lovers and runners alike." />
        <link rel="canonical" href="https://www.runningonbeans.com/" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "SportsClub",
            "name": "Running on Beans",
            "description": "Galway's newest running club, join us for a coffee run every Saturday!",
            "url": "https://www.runningonbeans.com/",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Galway",
              "addressRegion": "Galway",
              "addressCountry": "Ireland"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Customer Service",
              "url": "https://www.instagram.com/runningonbeans_"
            }
          }
          `}
        </script>
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <Info {...homeObjOne}/>
      <Info3 {...homeObjTwo}/>
      <Services />
      <Info2 {...homeObjThree}/>
      <Footer />
    </>
  )
}

export default Home
