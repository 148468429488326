import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../ButtonElements'; // Optionally remove if you're no longer using the Button component.
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, CarouselImage, ArrowButton } from './InfoElements';

const Info3 = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, images, primary, dark, dark2 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalRef = useRef(null);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(handleNextImage, 10000);
  };

  useEffect(() => {
    resetInterval();
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  <a
                    href='https://www.instagram.com/runningonbeansgalway'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <button
                      style={{
                        padding: '10px 20px',
                        backgroundColor: primary ? '#000' : '#fff',
                        color: primary ? '#fff' : '#000',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      {buttonLabel}
                    </button>
                  </a>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                {images && images.length > 0 ? (
                  <>
                    <ArrowButton direction="left" onClick={() => { handlePrevImage(); resetInterval(); }}>
                      &#8249;
                    </ArrowButton>
                    <CarouselImage src={images[currentImageIndex]} alt={`${alt} ${currentImageIndex + 1}`} />
                    <ArrowButton direction="right" onClick={() => { handleNextImage(); resetInterval(); }}>
                      &#8250;
                    </ArrowButton>
                  </>
                ) : (
                  <div>No images available</div>
                )}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Info3;
