import styled from 'styled-components'

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f55139;
  padding: 20px 0; 
  min-height: 800px; // Minimum height to start with

  // Removing fixed heights in media queries allows the container to grow
  @media screen and (max-width: 768px) {
    padding: 50px 20px; // Slightly adjust padding if needed
  }

  @media screen and (max-width: 480px) {
    padding: 50px 20px; // Consistent padding for small screens
  }
`


export const ServicesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* This ensures a 2-column layout */
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr; /* Maintain 2 columns until the width drops below 1000px */
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr; /* Switch to 1 column for widths 768px and below */
      padding: 0 20px;
    }
`

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding:  30px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }

  @media screen and (max-width: 480px) {
    padding: 15px; /* Example adjustment */
  }
`
export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;

`
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #0f222d;
  margin-bottom: 32px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`

export const ServicesDescription = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 32px;

  @media screen and (max-width: 480px) {
    font-size: 1.2rem; /* Reduced font size for smaller screens */
  }  
`

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`

export const PopupContainer = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  z-index: 100;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f55139;
`;

export const PopupButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background: #0f222d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: #5aa7a1;
  }
`;
