import fullWhiteLogo from '../../images/orange-navy-run-club-logo.png'; 
import reGroup from '../../images/re-group.jpg'; 
import limitedCoffeeGroup from '../../images/limited-coffee-group.jpeg'
import dollysGroup from '../../images/dollys-group.jpeg'
import sweetTreat from '../../images/sweet-treat.jpeg'
import baristaBus from '../../images/barista-bus.jpeg'
import group from '../../images/run-club-group.svg'; 


export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'About',
  headline: 'New Destinations Every Week!',
  description: 'Join us every Saturday for a run with friends and finishing at a different cafe every week!',
  buttonLabel: 'Get Started',
  to: 'signup',
  imgStart: false,
  img: fullWhiteLogo,
  alt: 'full-logo',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'events',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  headline: "Run every Saturday",
  description: "Follow us on Instagram to never miss a run!",
  buttonLabel: 'Instagram',
  to: 'signup',
  imgStart: true,
  images: [limitedCoffeeGroup, reGroup, dollysGroup, sweetTreat, baristaBus],
  alt: 'Piggybanck',
  dark: false,
  primary: true,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Join The Club!',
  headline: 'Join us on Strava to track your progress',
  buttonLabel: 'Running On Beans Strava',
  imgStart: false,
  img: group,
  alt: 'group',
  primary: false,
  darkText: true
};
